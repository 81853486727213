import {TPaintProcessValue} from '../services/project-types'

/**
 * To avoid circular dependencies we put some definitions
 * in a separate file
 */
export const BACK_PANEL_MIRROR = 'Täcksida med spegel'
export const NO_BACK_COVER = 'Ingen täcksida'
export const BACK_PANEL_PLAIN = 'Slät täcksida'

/**
 * Front panel on extractor hood, always present, slät or with mirror
 */
export const FRONT_PANEL_PLAIN = 'Täcksida, slät'
export const FRONT_PANEL_MIRROR = 'Täcksida med spegel'

/**
 * Side panels
 */
export const COVER_TYPE_POS = 0
export const COVER_EXTEND_POS = 1
export const COVER_CONSOLE_POS = 2

export const COVER_SIDE_PLAIN = 'Täcksida, slät'
export const COVER_SIDE_MIRROR = 'Täcksida med spegel'
export const COVER_SIDE_NONE = 'Ingen täcksida'
export const COVER_SIDE_EXTENDED = 'Förlängd'

export const COVER_SIDE_CONSOLE_CONVEX = 'Med konsol (konvex)'
export const COVER_SIDE_CONSOLE_CONCAVE = 'Med konsol (konkav)'
export const COVER_SIDE_CONSOLE_NONE = 'Utan konsoll'

/**
 * Fillers
 */
export const FILLER_TOP_POS = 0
export const FILLER_LEFT_POS = 1
export const FILLER_RIGHT_POS = 2
export const FILLER_BOTTOM_POS = 3

/******************************************************
 * Paint Process Variables
 ******************************************************/
/**
 * Paint Process Option Keys - They are used in Setting > Cabinet Options, where
 * users can set translated values to each key in various languages.
 */
export const LINSEED_COMPLETE = '1. Linoljefärg, komplett'
export const LINSEED_BASE = '2. Linoljefärg, grundmålning'
export const ORDINARY_PAINT_COMPLETE = '3. Vanlig färg, komplett'
export const NO_PAINT_WAXED_INSIDE = '4. Omålat på utsidorna, vaxat på insidorna'
export const NO_PAINT = '5. Helt omålat och obehandlat'
export const ORDINARY_PAINT_BASE = '6. Vanlig färg, grundmålning'
export const LINSEED_FACTORY_COMPLETE = '7. Linoljefärg, slutmålat i snickeriet'
export const ORDINARY_FACTORY_COMPLETE = '8. Vanlig färg, slutmålat i snickeriet'

const PaintOptionNames = [
  LINSEED_COMPLETE, // 1
  LINSEED_BASE, // 2
  ORDINARY_PAINT_COMPLETE, // 3
  NO_PAINT_WAXED_INSIDE, // 4
  NO_PAINT, // 5
  ORDINARY_PAINT_BASE, // 6
  LINSEED_FACTORY_COMPLETE, // 7
  ORDINARY_FACTORY_COMPLETE // 8
] as const
/**
 * All paint process keys as type
 */
export type TPaintOptionName = typeof PaintOptionNames[number]
/**
 * Map to link paint process key with the real paint process value.
 * Used for associating custom translations (Settings > Cabinet Options) to
 * PaintProcess values.
 */
export const TPaintOptionNumberToNameMap = new Map<TPaintProcessValue, TPaintOptionName>([
  [1, LINSEED_COMPLETE],
  [2, LINSEED_BASE],
  [3, ORDINARY_PAINT_COMPLETE],
  [4, NO_PAINT_WAXED_INSIDE],
  [5, NO_PAINT],
  [6, ORDINARY_PAINT_BASE],
  [7, LINSEED_FACTORY_COMPLETE],
  [8, ORDINARY_FACTORY_COMPLETE]
])
/******************************************************
 * End of Paint Process Variables
 ******************************************************/

/**
 * Door Attachment
 */
export const DOOR_ON_DOOR = 'Door on door'
export const SLIDES = 'Slides'
export const TBD = 'TBD'

/**
 * Hinges
 */
export const CLASSIC_HINGES = '1. Klassiskt lyftgångjärn'
export const MODERN_HINGES = '2. Moderna mjukstängande gångjärn'
export const MODERN_HINGES_TAP = '3. Modernt gångjärn med synlig tapp'

/**
 * Spice rack
 */
export const SELECTION_NONE = 'Ingen'
export const OPTION_COMBO = 'combo'
export const OPTION_COMBO_OLD = 'Combo spice/jar'
export const OPTION_JAR = 'jar'
export const OPTION_JAR_OLD = 'Jar rack'
export const OPTION_SPICE = 'spice'
export const OPTION_SPICE_OLD = 'Spice rack'
export const OPTION_NO = 'none'
export const OPTION_OFF = 'off'
export const DOOR_POS_LEFT = 'left'
export const DOOR_POS_ONLY = 'only'
export const DOOR_POS_MID = 'mid'
export const DOOR_POS_RIGHT = 'right'
export const DOOR_POS_TOP = 'top'
export const DOOR_POS_BOT = 'bot'
export const DOOR_POS_TOP_LEFT = 'top_left'
export const DOOR_POS_TOP_MID = 'top_mid'
export const DOOR_POS_TOP_RIGHT = 'top_right'
export const DOOR_POS_BOT_LEFT = 'bot_left'
export const DOOR_POS_BOT_MID = 'bot_mid'
export const DOOR_POS_BOT_RIGHT = 'bot_right'

/**
 * Door-types
 */

export const LUCKA_HOGALID = 'Lucka Högalid' // 1. Överfalsad...
export const LUCKA_DJUPADAL = 'Lucka Djupadal' // 2. Innanförliggande profilerad
export const LUCKA_RONNEHOLM = 'Lucka Rönneholm' // 3. Inn. Shaker
export const LUCKA_SUNNANA = 'Lucka Sunnanå' // 4. Doortype 4, shker med profil
export const LUCKA_MALMGARDEN = 'Lucka Malmgården'
export const LUCKA_SORGENFRI = 'Lucka Sorgenfri'
export const LUCKA_MELLANHEDEN = 'Lucka Mellanheden'

/**
 * Skirting
 */
export const STANDARD_SKIRTING = 'Standardsockel (inskjuten)'
export const OUTSIDE_SKIRTING = 'Utanpåliggande sockellist'

/**
 * Hidden drawers
 */

export const DRAWER_HIGH = 'Utdragslåda, hög'
export const DRAWER_LOW = 'Utdragslåda, låg'
export const SHELF = 'Hyllplan'

// Door
export const DOOR_NO_DOOR_SELECTED = 'Ingen lucka'
export const DOOR_STANDARD_DOOR = 'Standardlucka'
export const DOOR_GLASS_DOOR = 'Vitrinlucka'
export const DOOR_PAINTED_INSIDE = 'Ja'
export const DOOR_NO_PAINTED_INSIDE = 'Nej'

export const DRAWER_DOOR_DOOR = 'Lucka'
export const DRAWER_DOOR_DRAWER = 'Låda'

/**
 * The selectable options for Extractor Hood
 */
export const EXTRACTOR_HOOD_SIDE_FRONT = 'front'
export const EXTRACTOR_HOOD_SIDE_LEFT = 'left'
export const EXTRACTOR_HOOD_SIDE_RIGHT = 'right'
export const EXTRACTOR_HOOD_MIRROR = 'Mirror'
export const EXTRACTOR_HOOD_PLAIN = 'Plain'
export const EXTRACTOR_HOOD_UNPAINTED = 'No coverside'
export const EXTRACTOR_HOOD_NO_CONSOLE = 'No console'
export const EXTRACTOR_HOOD_CONCAVE_CONSOLE = 'With console (concave)'
export const EXTRACTOR_HOOD_CONVEX_CONSOLE = 'With console (convex)'

export const HIDDEN_DRAWER_SINK_ONE = 'En extra utdragslåda'
export const HIDDEN_DRAWER_SINK_TWO = 'Två extra utdragslådor'
export const HIDDEN_DRAWER_SINK_NONE = 'Ingen utdragslåda'